import React from "react";
import { useI18nMessage } from "@lookiero/i18n-react";
import { I18nMessages, PREFIX } from "../../../i18n/i18n";
import { ActionButton } from "../../molecules/actionButton/ActionButton";
import { style } from "./Like.style";
const Like = ({ liking, onPress }) => {
    const likeText = useI18nMessage({ id: I18nMessages.LIKE, prefix: PREFIX });
    return (React.createElement(ActionButton, { icon: "heart", liking: liking, motionValue: { translateX: -16, opacity: 0 }, style: { textMotion: style.likeTextMotion }, testID: "like-button", text: likeText, value: true, onPress: onPress }));
};
export { Like };
