import { StyleSheet } from "react-native";
const style = StyleSheet.create({
    buttonIcon: {
        padding: 0,
    },
    title: {
        justifyContent: "space-between",
    },
});
export { style };
