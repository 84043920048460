import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space6 } = theme();
const style = StyleSheet.create({
    container: {
        marginRight: space6,
    },
    dislikeTextMotion: {
        left: "-100%",
    },
});
export { style };
