const toGameProjection = ({ gameProductVariants }) => ({
    gameProductVariants,
    lastGame: false,
});
const httpGameView = ({ httpPost }) => async ({ signal }) => await httpPost({
    endpoint: "/get-game-by-customer",
    body: {},
    signal,
    result: {
        error: null,
        success: (response) => toGameProjection({ gameProductVariants: response }),
    },
});
export { httpGameView };
