import { i18n } from "@lookiero/i18n-react";
import { fetchTranslations, translationExternalEndpoint } from "@lookiero/sty-psp-i18n";
import { bootstrap as lookAndLikeBootstrap } from "./src/infrastructure/delivery/bootstrap";
import { root } from "./src/infrastructure/ui/Root";
const bootstrap = ({ apiUrl, getAuthToken, sentry, translations }) => {
    const { Component: Messaging } = lookAndLikeBootstrap({ apiUrl, getAuthToken });
    const I18n = i18n({
        fetchTranslation: fetchTranslations({ translations }),
        contextId: "LookAndLikeI18n",
    });
    return {
        root: root({ Messaging, I18n, sentry }),
    };
};
export { bootstrap, translationExternalEndpoint };
