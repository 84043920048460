import { gamePlayed } from "./gamePlayed";
const playGameHandler = () => async ({ aggregateRoot, command }) => {
    const { aggregateId, like, view } = command;
    return {
        ...aggregateRoot,
        like,
        view,
        domainEvents: [gamePlayed({ aggregateId })],
    };
};
export { playGameHandler };
