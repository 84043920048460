import React, { useState } from "react";
import { Image } from "react-native";
import { COLOR, DISPLAY, MOTION_TIMING, POSITION, SIZE, Motion, useDevice, useSwipe } from "@lookiero/aurora";
import { useMediaImage } from "@lookiero/sty-psp-ui";
import { style, WIDTH } from "./Card.style";
import { calcCardMotion } from "./calcCardMotion";
import { CARD, cardType } from "./cardType";
const LAYER = {
    [CARD.ACTIVE]: SIZE.L,
    [CARD.NEXT]: SIZE.M,
    [CARD.LATER]: SIZE.S,
    [CARD.BASE]: SIZE.XS,
};
const Card = ({ imageUrl, card, liked, onSwipe, onSwiping }) => {
    const { screen } = useDevice();
    const cdnImageUrl = useMediaImage();
    const [coords, setCoords] = useState({ x: 0, y: 0 });
    const handleSwipe = useSwipe({
        onSwiping: ({ deltaX: x, deltaY: y, right }) => {
            if (onSwiping === undefined) {
                return;
            }
            setCoords({ x, y });
            onSwiping(Math.abs(x) >= WIDTH / 3 ? right : undefined);
        },
        onSwiped: (gesture) => {
            if (onSwipe === undefined || onSwiping === undefined) {
                return;
            }
            const { deltaX: x, right } = gesture || {};
            if (x === undefined || right === undefined) {
                return;
            }
            if (Math.abs(x) >= WIDTH / 3) {
                onSwipe(right);
            }
            else {
                setCoords({ x: 0, y: 0 });
            }
            onSwiping(undefined);
        },
    });
    const { isActive, isNext, isLater, isBase } = cardType({ card });
    const isMoving = liked === undefined && (coords.x !== 0 || coords.y !== 0);
    const onScreen = isActive || isNext || isLater || isBase || liked !== undefined;
    const swipeable = isActive && onSwipe !== undefined && onSwiping !== undefined;
    return (React.createElement(Motion, { ...(swipeable && isActive ? handleSwipe : undefined), backgroundColor: COLOR.BG_BASE, disabled: isMoving || !onScreen, display: DISPLAY.FLEX, layer: liked !== undefined ? SIZE.XL : LAYER[card], position: POSITION.ABSOLUTE, style: [style.card, isActive && style.active, onScreen && !isActive && style.visible], timing: liked === undefined ? MOTION_TIMING.SPRING : undefined, value: calcCardMotion({ coords, card, liked, screen }) }, onScreen && imageUrl && (React.createElement(Image, { resizeMode: "cover", source: { uri: cdnImageUrl({ url: imageUrl, width: WIDTH }) }, style: style.card }))));
};
export { Card };
