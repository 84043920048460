import { bootstrap as messagingBootstrap } from "@lookiero/messaging-react";
import { PLAY_GAME } from "../../domain/game/command/playGame";
import { playGameHandler } from "../../domain/game/model/game";
import { VIEW_GAME, viewGameHandler } from "../../projection/game/viewGame";
const MESSAGING_CONTEXT_ID = "LookAndLike";
const baseBootstrap = ({ gameView, getGame, saveGame, gamesDependencies }) => messagingBootstrap({ id: MESSAGING_CONTEXT_ID })
    .query(VIEW_GAME, viewGameHandler, {
    view: gameView,
})
    .command(PLAY_GAME, playGameHandler)(getGame, saveGame, ...gamesDependencies)
    .build();
export { MESSAGING_CONTEXT_ID, baseBootstrap };
