import React from "react";
import { useI18nMessage } from "@lookiero/i18n-react";
import { I18nMessages, PREFIX } from "../../../i18n/i18n";
import { ActionButton } from "../../molecules/actionButton/ActionButton";
import { style } from "./Dislike.style";
const Dislike = ({ liking, onPress }) => {
    const dislikeText = useI18nMessage({ id: I18nMessages.DISLIKE, prefix: PREFIX });
    return (React.createElement(ActionButton, { icon: "close", liking: liking, motionValue: { translateX: 16, opacity: 0 }, style: { container: style.container, textMotion: style.dislikeTextMotion }, testID: "dislike-button", text: dislikeText, value: false, onPress: onPress }));
};
export { Dislike };
