import { useQuery } from "@lookiero/messaging-react";
import { viewGame } from "../../../../projection/game/viewGame";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
const useViewGame = () => useQuery({
    query: viewGame(),
    contextId: MESSAGING_CONTEXT_ID,
    options: {
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
    },
});
export { useViewGame };
