import React, { useCallback, useEffect, useState } from "react";
import { ALIGN, DISPLAY, FLEX_DIRECTION, POINTER, POSITION, SPACE, SIZE, Motion, Progress, Text, View, } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { I18nMessages, PREFIX } from "../../../i18n/i18n";
import { Card } from "../../atoms/card/Card";
import { Dislike } from "../../atoms/dislike/Dislike";
import { Like } from "../../atoms/like/Like";
import { Title } from "../../atoms/title/Title";
import { style } from "./LookAndLike.style";
import { HowToModal } from "./components/howToModal/HowToModal";
const FOCUS_EVENT_TIMEOUT = 250;
const FADE_IN = { opacity: 1, scale: 1 };
const FADE_OUT = { opacity: 0, scale: 0.8 };
let timeout;
const LookAndLike = ({ gameProductVariants, lastGame, headerless = false, progress, swipeable = true, style: customStyle, onBlur, onChange, onFocus, onReady, }) => {
    const descriptionText = useI18nMessage({ id: I18nMessages.DESCRIPTION, prefix: PREFIX });
    const lastGameTitleText = useI18nMessage({ id: I18nMessages.LAST_GAME_TITLE, prefix: PREFIX });
    const lastGameDescriptionTEXT = useI18nMessage({ id: I18nMessages.LAST_GAME_DESCRIPTION, prefix: PREFIX });
    const successTitleText = useI18nMessage({ id: I18nMessages.SUCCESS_TITLE, prefix: PREFIX });
    const successDescriptionText = useI18nMessage({ id: I18nMessages.SUCCESS_DESCRIPTION, prefix: PREFIX });
    const [active, setActive] = useState(0);
    const [liking, setLiking] = useState();
    const [values, setValues] = useState([]);
    const [howToModalVisible, setHowToModalVisiable] = useState(false);
    const handleOnDismissHowToModal = useCallback(() => setHowToModalVisiable(false), []);
    const handleOnPressTitle = useCallback(() => setHowToModalVisiable(true), []);
    useEffect(() => {
        onReady?.();
    }, [onReady]);
    const handleSwipe = useCallback((like = true) => {
        setActive(active + 1);
        setValues([...values, like]);
        const item = gameProductVariants[active];
        if (item) {
            onChange({ id: item.id, like });
        }
    }, [active, gameProductVariants, onChange, values]);
    const handleSwiping = useCallback((nextLiking) => {
        setLiking(nextLiking);
        onFocus?.();
        if (onBlur) {
            clearTimeout(timeout);
            timeout = setTimeout(() => onBlur(), FOCUS_EVENT_TIMEOUT);
        }
    }, [onBlur, onFocus]);
    const finished = active === gameProductVariants.length || lastGame;
    return (React.createElement(React.Fragment, null,
        React.createElement(HowToModal, { visible: howToModalVisible, onDismiss: handleOnDismissHowToModal }),
        React.createElement(Motion, { alignItems: ALIGN.CENTER, display: DISPLAY.FLEX, flexDirection: FLEX_DIRECTION.COLUMN, position: POSITION.RELATIVE, style: [style.container, customStyle], testID: "look-and-like", wide: true },
            !headerless && React.createElement(Title, { onPress: handleOnPressTitle }),
            progress && gameProductVariants.length > 0 && (React.createElement(Progress, { active: active, length: gameProductVariants.length + 1, marginBottom: SPACE.SPACE_4, paddingHorizontal: SPACE.SPACE_6 })),
            React.createElement(View, { layer: SIZE.XS, style: style.content },
                React.createElement(View, { position: POSITION.RELATIVE, style: style.cards }, gameProductVariants.map(({ id, image }, index) => (React.createElement(Card, { key: id, card: active - index, imageUrl: image, liked: values ? values[index] : false, onSwipe: swipeable ? handleSwipe : undefined, onSwiping: swipeable ? handleSwiping : undefined })))),
                React.createElement(Motion, { alignItems: ALIGN.CENTER, display: DISPLAY.FLEX, flexDirection: FLEX_DIRECTION.COLUMN, justifyContent: ALIGN.CENTER, pointerEvents: POINTER.NONE, position: POSITION.ABSOLUTE, style: style.success, value: finished ? FADE_IN : FADE_OUT },
                    React.createElement(Text, { align: ALIGN.CENTER, as: "h2", display: DISPLAY.BLOCK, level: 2, selectable: false, heading: true }, lastGame ? lastGameTitleText : successTitleText))),
            React.createElement(Text, { align: ALIGN.CENTER, display: DISPLAY.BLOCK, level: 1, marginBottom: SPACE.SPACE_6, selectable: false, style: finished ? { opacity: 0 } : { opacity: 1 } }, descriptionText),
            React.createElement(View, { display: DISPLAY.FLEX, justifyContent: ALIGN.CENTER, marginTop: SPACE.SPACE_4, position: POSITION.RELATIVE, wide: true },
                gameProductVariants.length > 0 && (React.createElement(Motion, { pointer: finished ? POINTER.NONE : undefined, value: finished ? FADE_OUT : FADE_IN },
                    React.createElement(View, { alignItems: ALIGN.CENTER, display: DISPLAY.FLEX, flexDirection: FLEX_DIRECTION.ROW, justifyContent: ALIGN.CENTER, style: style.likeDislikeContainer },
                        React.createElement(Dislike, { liking: liking, onPress: handleSwipe }),
                        React.createElement(Like, { liking: liking, onPress: handleSwipe })))),
                React.createElement(Motion, { pointer: POINTER.NONE, position: POSITION.ABSOLUTE, value: finished ? FADE_IN : FADE_OUT, delay: true, wide: true },
                    React.createElement(Text, { align: ALIGN.CENTER, as: "p", display: DISPLAY.BLOCK, level: 3, marginHorizontal: SPACE.SPACE_8, marginTop: SPACE.SPACE_2, selectable: false, detail: true }, lastGame ? lastGameDescriptionTEXT : successDescriptionText))))));
};
export { LookAndLike };
