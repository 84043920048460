// TODO properly export MessagingRoot from @lookiero/messaging-react
import React, { useCallback } from "react";
import { Platform } from "react-native";
import { Spinner } from "@lookiero/aurora";
import { Locale } from "@lookiero/sty-psp-locale";
import { sentryLoggerHOC, sentryLogger } from "@lookiero/sty-psp-logging";
import { LookAndLike } from "./views/LookAndLike";
const root = ({ Messaging, I18n, development, sentry }) => {
    const logger = sentryLogger(sentry);
    const Root = ({ locale = Locale.en_GB, ...props }) => {
        const handleOnI18nError = useCallback((error) => logger.captureException(error), []);
        return (React.createElement(Messaging, { includeReactQueryDevTools: Platform.OS === "web" },
            React.createElement(I18n, { loader: React.createElement(Spinner, null), locale: locale, onError: development ? undefined : handleOnI18nError },
                React.createElement(LookAndLike, { ...props }))));
    };
    const hoc = sentryLoggerHOC({ logger });
    /**
     * It's important to not call sentryLoggerHOC each time this function gets called.
     * That's why its called outside this method.
     */
    return ({ customerId }) => hoc({ customerId })(Root);
};
export { root };
