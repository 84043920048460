import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space20 } = theme();
const MIN_HEIGHT = 74;
const style = StyleSheet.create({
    button: {
        minHeight: MIN_HEIGHT,
    },
    message: {
        width: space20,
    },
    textMotion: {
        width: space20,
    },
});
export { style };
