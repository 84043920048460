import { isGamePlayed } from "../../../../domain/game/model/gamePlayed";
const httpGamesPlay = ({ httpPost }) => async ({ aggregateId, domainEvents, like, view }) => {
    const gamePlayed = domainEvents.find(isGamePlayed);
    if (!gamePlayed) {
        return;
    }
    await httpPost({
        endpoint: "/play-game",
        body: {
            id: aggregateId,
            response: like,
            place: view,
        },
    });
};
export { httpGamesPlay };
