var CARD;
(function (CARD) {
    CARD[CARD["ACTIVE"] = 0] = "ACTIVE";
    CARD[CARD["NEXT"] = -1] = "NEXT";
    CARD[CARD["LATER"] = -2] = "LATER";
    CARD[CARD["BASE"] = -3] = "BASE";
})(CARD || (CARD = {}));
const cardType = ({ card = 0 }) => ({
    isActive: card === CARD.ACTIVE,
    isNext: card === CARD.NEXT,
    isLater: card === CARD.LATER,
    isBase: card === CARD.BASE,
});
export { CARD, cardType };
