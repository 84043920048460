import { cardType } from "./cardType";
const FALLBACK_WIDTH = 1024;
const calcCardMotion = ({ coords: { x = 0, y = 0 }, card, liked, screen }) => {
    const { isActive, isNext, isLater } = cardType({ card });
    const unknownScreen = !(screen.S || screen.M || screen.L);
    return isActive
        ? {
            top: y,
            left: x,
            rotate: `${x >= 0 ? "" : "-"}${x === 0 ? 0 : Math.abs(x / 20)}deg`,
        }
        : isNext || isLater
            ? {
                top: 0,
                left: 0,
                rotate: `${isNext ? "" : "-"}4deg`,
            }
            : liked !== undefined
                ? {
                    top: y,
                    left: x + (unknownScreen ? FALLBACK_WIDTH : screen.width) * (liked ? 1 : -1) * (screen.L ? 1 : 1.25),
                    rotate: `${liked ? "" : "-"}45deg`,
                }
                : {
                    top: y,
                    left: x,
                    rotate: `${x >= 0 ? "" : "-"}${x === 0 ? 0 : Math.abs(x / 20)}deg`,
                };
};
export { calcCardMotion };
