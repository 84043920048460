import { Platform } from "react-native";
import { fetchHttpPost } from "@lookiero/sty-psp-http";
import { VERSION } from "../../version";
import { getGame, saveGame } from "../domain/game/model/httpGames";
import { httpGameView } from "../projection/game/httpGameView";
import { baseBootstrap } from "./baseBootstrap";
const device = Platform.OS;
const bootstrap = ({ apiUrl, getAuthToken }) => {
    const httpPost = fetchHttpPost({ apiUrl, getAuthToken, device, version: VERSION });
    return baseBootstrap({
        gameView: httpGameView({ httpPost }),
        getGame,
        saveGame,
        gamesDependencies: [{ httpPost }],
    });
};
export { bootstrap };
