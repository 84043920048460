import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { borderRadius3, elevationLayerM, shadowColor, shadowOffset, shadowOpacityS, shadowOpacityM, shadowRadius } = theme();
const ASPECT_RATIO = 250 / 312;
const WIDTH = 300;
const BACKGROUND_COLOR = "#F0F0F0";
const style = StyleSheet.create({
    active: {
        elevation: elevationLayerM,
        shadowColor: shadowColor,
        shadowOffset: shadowOffset,
        shadowOpacity: shadowOpacityM,
        shadowRadius: shadowRadius,
    },
    card: {
        aspectRatio: ASPECT_RATIO,
        backgroundColor: BACKGROUND_COLOR,
        borderRadius: borderRadius3,
        width: WIDTH,
    },
    visible: {
        elevation: 1,
        shadowColor: shadowColor,
        shadowOffset: shadowOffset,
        shadowOpacity: shadowOpacityS,
        shadowRadius: shadowRadius,
    },
});
export { style, WIDTH, ASPECT_RATIO };
