import React from "react";
import { ALIGN, DISPLAY, FLEX_DIRECTION, Text, SPACE, View } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { ButtonIcon } from "@lookiero/sty-psp-ui";
import { I18nMessages, PREFIX } from "../../../i18n/i18n";
import { style } from "./Title.style";
const Title = ({ onPress }) => {
    const titleText = useI18nMessage({ id: I18nMessages.TITLE, prefix: PREFIX });
    return (React.createElement(View, { alignItems: ALIGN.CENTER, display: DISPLAY.FLEX, flexDirection: FLEX_DIRECTION.ROW, marginBottom: SPACE.SPACE_8, style: style.title, wide: true },
        React.createElement(Text, { align: ALIGN.CENTER, as: "p", display: DISPLAY.BLOCK, level: 2, selectable: false, action: true }, titleText),
        React.createElement(ButtonIcon, { name: "info", style: { button: style.buttonIcon }, testID: "info-button-icon", onPress: onPress })));
};
export { Title };
