import { useCallback } from "react";
import { useCommand } from "@lookiero/messaging-react";
import { playGame } from "../../../../domain/game/command/playGame";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
const usePlayGame = ({ view, logger }) => {
    const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });
    const play = useCallback(async ({ id, like }) => {
        try {
            await commandBus(playGame({ aggregateId: id, like, view }));
        }
        catch (error) {
            logger.captureException(error);
        }
    }, [commandBus, logger, view]);
    return [play, status];
};
export { usePlayGame };
