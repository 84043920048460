const PREFIX = "look_and_like.";
var I18nMessages;
(function (I18nMessages) {
    I18nMessages["TITLE"] = "title";
    I18nMessages["DESCRIPTION"] = "text";
    I18nMessages["LIKE"] = "button.like";
    I18nMessages["DISLIKE"] = "button.dislike";
    I18nMessages["SUCCESS_TITLE"] = "success.title";
    I18nMessages["SUCCESS_DESCRIPTION"] = "success.text";
    I18nMessages["LAST_GAME_TITLE"] = "success.last_game.title";
    I18nMessages["LAST_GAME_DESCRIPTION"] = "success.last_game.text";
    I18nMessages["HOW_TO_TITLE"] = "howto.title";
    I18nMessages["HOW_TO_PARAGRAPH_1"] = "howto.paragraph_1";
    I18nMessages["HOW_TO_PARAGRAPH_2"] = "howto.paragraph_2";
})(I18nMessages || (I18nMessages = {}));
export { I18nMessages, PREFIX };
