import React, { useCallback, useEffect, useState } from "react";
import { ALIGN, BUTTON_VARIANT, DISPLAY, FLEX_DIRECTION, POSITION, SPACE, Button, Icon, Motion, Text, View, MOTION_TIMING, } from "@lookiero/aurora";
import { style } from "./ActionButton.style";
const TEXT_TIMEOUT = 800;
const ACTIVE = { scale: 1.15, opacity: 1 };
const INACTIVE = { scale: 0.9, opacity: 0.3 };
const STANDBY = { scale: 1, opacity: 1 };
const VISIBLE = { translateX: 0, opacity: 1 };
const ActionButton = ({ text, icon, value, motionValue, liking, testID = "action-button", style: customStyle, onPress, }) => {
    const [message, setMessage] = useState(undefined);
    useEffect(() => {
        const timeout = setTimeout(() => setMessage(undefined), TEXT_TIMEOUT);
        return () => clearTimeout(timeout);
    }, [message]);
    const handlePress = useCallback(() => {
        setMessage(value);
        onPress(value);
    }, [onPress, value]);
    const isLiking = liking !== undefined;
    const hasMessage = message !== undefined;
    return (React.createElement(View, { alignItems: ALIGN.CENTER, display: DISPLAY.FLEX, flexDirection: FLEX_DIRECTION.ROW, position: POSITION.RELATIVE, style: customStyle?.container },
        React.createElement(Motion, { position: POSITION.ABSOLUTE, style: [style.textMotion, customStyle?.textMotion], timing: MOTION_TIMING.SPRING, value: (hasMessage && message === value) || (isLiking && liking === value) ? VISIBLE : motionValue },
            React.createElement(Text, { display: DISPLAY.BLOCK, marginHorizontal: SPACE.SPACE_4, style: style.message, detail: true, upperCase: true }, text)),
        React.createElement(Motion, { timing: MOTION_TIMING.SPRING, value: isLiking ? (liking === value ? ACTIVE : INACTIVE) : STANDBY },
            React.createElement(Button, { style: style.button, testID: testID, variant: BUTTON_VARIANT.SECONDARY, onPress: handlePress },
                React.createElement(Icon, { name: icon })))));
};
export { ActionButton };
