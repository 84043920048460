import React, { useCallback, useEffect } from "react";
import { CommandStatus, QueryStatus } from "@lookiero/messaging-react";
import { useLogger } from "@lookiero/sty-psp-logging";
import { usePlayGame } from "../../domain/game/react/usePlayGame";
import { useViewGame } from "../../projection/game/react/useViewGame";
import { LookAndLike as LookAndLikeComponent, } from "../components/organisms/lookAndLike/LookAndLike";
const LookAndLike = ({ view, standalone = false, onError, ...props }) => {
    const logger = useLogger();
    const [game, gameStatus] = useViewGame();
    const [playGame, playGameStatus] = usePlayGame({ view, logger });
    const handleOnChange = useCallback(({ id, like }) => playGame({ id, like }), [playGame]);
    useEffect(() => {
        if (game === null || gameStatus === QueryStatus.ERROR || playGameStatus === CommandStatus.ERROR) {
            onError?.();
        }
    }, [game, gameStatus, onError, playGameStatus]);
    if (gameStatus === QueryStatus.LOADING || (!game && !standalone)) {
        return null;
    }
    return (React.createElement(LookAndLikeComponent, { ...props, gameProductVariants: game?.gameProductVariants || [], lastGame: game?.lastGame || false, onChange: handleOnChange }));
};
export { LookAndLike };
