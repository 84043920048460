import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
import { ASPECT_RATIO, WIDTH } from "../../atoms/card/Card.style";
const { space8 } = theme();
const style = StyleSheet.create({
    cards: {
        aspectRatio: ASPECT_RATIO,
        width: WIDTH,
    },
    container: {
        zIndex: 0,
    },
    content: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: space8,
        position: "relative",
        width: "100%",
    },
    likeDislikeContainer: {
        height: 72,
    },
    success: {
        aspectRatio: ASPECT_RATIO,
    },
});
export { style };
