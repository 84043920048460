import React from "react";
import { SPACE, COLOR, Portal, Modal, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { I18nMessages, PREFIX } from "../../../../../i18n/i18n";
const HowToModal = ({ visible, onDismiss }) => {
    const titleText = useI18nMessage({ id: I18nMessages.HOW_TO_TITLE, prefix: PREFIX });
    const descriptionText = useI18nMessage({ id: I18nMessages.HOW_TO_PARAGRAPH_1, prefix: PREFIX });
    const otherDescriptionText = useI18nMessage({ id: I18nMessages.HOW_TO_PARAGRAPH_2, prefix: PREFIX });
    return (React.createElement(Portal, { id: "modal-look-and-like-how-to" },
        React.createElement(Modal, { isVisible: visible, title: titleText, onClose: onDismiss },
            React.createElement(Text, { as: "p", color: COLOR.TEXT_MEDIUM, marginBottom: SPACE.SPACE_4 }, descriptionText),
            React.createElement(Text, { as: "p", color: COLOR.TEXT_MEDIUM }, otherDescriptionText))));
};
export { HowToModal };
